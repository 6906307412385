.react-datepicker-wrapper .react-datepicker__input-container input {
    border: 1px solid  rgba(0, 0, 0, 0.247);
    padding: 0.9rem;
    border-radius: 4px;
    min-width: 200px;
    min-height: 50px;
}

.react-datepicker__header {
    background-color: #22b8b5;
}